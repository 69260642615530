/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { from, Observable }                                        from 'rxjs';

import { Configuration }                                     from '../configuration';
import { child, get, getDatabase, push, ref, remove, set, update } from 'firebase/database';
import { environment } from '../../../../environments/environment';
import { Category } from '../model/category';
import { CategoryControllerGetCategoryRequestParams, CategoryControllerGetManyCategoriesRequestParams, CategoryServiceInterface } from './category.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class CategoryService implements CategoryServiceInterface {

    //protected basePath = 'https://vrcardioexplorer-default-rtdb.firebaseio.com';
    protected basePath = environment.api;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        this.configuration.basePath = this.basePath;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public async categoryControllerGetManyCategories(requestParameters: CategoryControllerGetManyCategoriesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Promise<Observable<any>> {
        /*const dbRef = ref(getDatabase());
        let dbApiParts = environment.api.split("/");
        let dbType = dbApiParts[dbApiParts.length-1];
        let category : Array<Category> = [];
        return from(get(child(dbRef, dbType + `/categories`)).then((snapshot) => {
            if (snapshot.exists()) {                
                snapshot.forEach(function(childSnapshot) {
                    let childKey : string = childSnapshot.key!;
                    let childVal : string = childSnapshot.val();
                    let childData : Category = {title:"", description: ""};
                    childData.title = childKey.split("_").join(" ");
                    childData.description = childVal;
                    category.push(childData)
                })
            }
        }).then(val => {
            return (category);
        }))*/
        let url = environment.getmanynewscategories;
        let result: any = await fetch(url)
        .then(response => response.json())
       .catch(error => {
            console.error('Error:', error);
        });
        return result;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoryControllerGetCategory(requestParameters: CategoryControllerGetCategoryRequestParams, 
        observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Category>{
        const dbRef = ref(getDatabase());
        let dbApiParts = environment.api.split("/");
        let dbType = dbApiParts[dbApiParts.length-1];
        let category : Array<Category> = [];
        return from(get(child(dbRef, dbType + `/categories/${requestParameters.title}`)).then((snapshot) => {
            let value :Category = snapshot.val();
            category.push(value);
        }).then(val => {
            return (category[0])
        }))
    }
}
