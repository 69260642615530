import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { RootState } from "../store";
import * as FromUser from "../store/user/selectors";
import { Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { environment } from '../../environments/environment';

declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-swagger-ui',
  templateUrl: './swagger-ui.component.html',
  styleUrls: ['./swagger-ui.component.scss']
})
export class SwaggerUiComponent implements OnInit {
  
  public userData$: Observable<string>;

  private accesToSwagger : boolean = false;

  ngOnInit(): void {
    if(this.accesToSwagger){
      const ui = SwaggerUIBundle({
        dom_id: '#swagger-ui',
        layout: 'BaseLayout',
        presets: [
          SwaggerUIBundle.presets.apis,
          SwaggerUIBundle.SwaggerUIStandalonePreset
        ],
        url: environment.swaggerURL, 
        docExpansion: 'none',
        operationsSorter: 'alpha'
      });
    } else {
      let parsedUrl = this.router.url.split('/#/');
      window.location.replace(parsedUrl[0] + '/#/login');  
    }
  }

  constructor(
    private router: Router, 
    private t: TranslateService, 
    private store: Store<RootState>, 
    public afAuth: AngularFireAuth,
    private angularStorage: AngularFireStorage, 
    ) {
      this.userData$ = this.store.select(FromUser.selectUserData);
      this.userData$.subscribe((val:any)=>{
        this.accesToSwagger = (val != null && val != undefined && val.id != '');
      });
    }

}