import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as Cardio3DCategoryActions from "./actions";
import { Cardio3DCategoryService } from "../../services/api/api/cardio3DCategory.service";

@Injectable()
export class Cardio3DCategoryEffects {
  public loadManyCardio3DCategories$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      
      ofType(Cardio3DCategoryActions.loadManyCardio3DCategories),
      mergeMap(async ({ page, limit }) => {
        try {
          const result : any = await this.cardio3DCategoryService.cardio3DCategoryControllerGetManyCardio3DCategories({ page, limit}); 
          return Cardio3DCategoryActions.loadManyCardio3DCategoriesSuccess({ manyCardio3DCategories: result });
        } catch (error) {
          console.error(error)
          return Cardio3DCategoryActions.loadManyCardio3DCategoriesFailure();
        }
      }),
    ),
  );

  constructor(private actions$: Actions, private cardio3DCategoryService: Cardio3DCategoryService) {}
}
