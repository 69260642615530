import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as Cardio3DActions from "./actions";
import { Cardio3D } from "../../services/api";

export const cardio3DReducer = createReducer(
  initialState,

  on(Cardio3DActions.loadManyCardio3D, Cardio3DActions.loadCardio3D, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(Cardio3DActions.loadManyCardio3DFailure, Cardio3DActions.loadCardio3DFailure, state => ({
    ...state,
    loading: false,
  })),

  on(Cardio3DActions.loadManyCardio3DSuccess, (state, { manyCardio3D, filtered }) => {
    const newCardio3D: { [key: string]: Cardio3D } = {};
    // Check query petition result
    if(manyCardio3D.cardio3D != undefined && manyCardio3D.cardio3D.length > 0){
      
      if(!filtered){
        let cardio3DArray = manyCardio3D.cardio3D[0];
        Object.keys(cardio3DArray).map((key: string) => {

          newCardio3D[key!] = cardio3DArray[key];
        });
      }else{
        manyCardio3D.cardio3D.forEach((cardio3D: Cardio3D) => {
          newCardio3D[cardio3D.id!] = cardio3D;
        });
      }
      
      let lastTotal = 0;
      if(manyCardio3D.isFiltered){
        if(manyCardio3D.cardio3D != undefined && manyCardio3D.cardio3D.length > 0){
          lastTotal = Object.keys(newCardio3D).length;
        }
      } else {
        lastTotal = state.total;
      }
      Object.keys(state.manyCardio3D).forEach(element => {
        if(newCardio3D[element] == undefined){
          newCardio3D[element] = state.manyCardio3D[element];
        }
      });
      let manyCardio3DArrayAux : Cardio3D[] = [...state.manyCardio3DArray];
      Object.values(newCardio3D).forEach(cardio3D => {
        let inserted : boolean = false;
        manyCardio3DArrayAux.forEach(element => {
          if(element.id == cardio3D.id){
            inserted = true;
          }
        });
        if(!inserted){
          manyCardio3DArrayAux = [...manyCardio3DArrayAux, cardio3D]
        }
      });
      return {
        ...state,
        total: manyCardio3DArrayAux.length,
        manyCardio3D: {
          ...newCardio3D
        },
        manyCardio3DArray: manyCardio3DArrayAux,
        loading: false,
      };
    } else {
      return {
        ...state,
        loading: false,
      };
    }
  }),

  on(Cardio3DActions.loadCardio3DSuccess, (state, { cardio3D }) => {
    return {
      ...state,
      manyCardio3D: {
        ...state.manyCardio3D,
        [cardio3D.id!]: cardio3D,
      },
      loading: false,
    };
  }),

  on(Cardio3DActions.getCardio3DSuccess, (state, { cardio3D }) => {
    return {
      ...state,
      currentCardio3D: cardio3D[0],
      loading: false,
    }
  }),

  on(Cardio3DActions.getCardio3DFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(Cardio3DActions.updateCardio3DSuccess, (state, { cardio3D}) => {
    return {
      ...state,
      loading: false,
      category: cardio3D.category,
      createdAt: cardio3D.createdAt,
      description: cardio3D.description,
      id: cardio3D.id,
      link: cardio3D.link,
      preview: cardio3D.preview,
      previewHover: cardio3D.previewHover,
      title: cardio3D.title,
      type: cardio3D.type,
      userId: cardio3D.userId,
    }
  }),

  on(Cardio3DActions.updateCardio3DFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(Cardio3DActions.deleteCardio3DSuccess, (state, { cardio3DId }) => {
    const manyCardio3D = Object.values(state.manyCardio3D).filter(cardio3D => cardio3D.id !== cardio3DId);
    const newCardio3D: { [key: string]: Cardio3D } = {};
    manyCardio3D.forEach((cardio3D: Cardio3D) => (newCardio3D[cardio3D.id!] = cardio3D));
    return {
      ...state, 
      manyCardio3DArray: manyCardio3D,
      manyCardio3D: newCardio3D,
      loading: false,
    };  
  }),

  on(Cardio3DActions.deleteCardio3DFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),
);
