import { createAction, props } from "@ngrx/store";
import { ManyCardio3D, Cardio3D, Cardio3DControllerGetManyCardio3DRequestParams } from "../../services/api";

export const loadManyCardio3D = createAction("[Cardio3D] Load Many Cardio3D", 
    props<Cardio3DControllerGetManyCardio3DRequestParams>());
export const loadManyCardio3DSuccess = createAction("[Cardio3D] Load Many Cardio3D Success", props<{ manyCardio3D: any, filtered: boolean }>());
export const loadManyCardio3DFailure = createAction("[Cardio3D] Load Many Cardio3D Failure");

export const loadCardio3D = createAction("[Cardio3D] Load Cardio3D", props<{ cardio3DId: string }>());
export const loadCardio3DSuccess = createAction("[Cardio3D] Load Cardio3D Success", props<{ cardio3D: Cardio3D }>());
export const loadCardio3DFailure = createAction("[Cardio3D] Load Cardio3D Failure");

export const updateCardio3D = createAction("[Cardio3D] Cardio3D Update", props<{ cardio3D: any }>());
export const updateCardio3DSuccess = createAction("[Cardio3D] Update Cardio3D Success", props<{ cardio3D: any }>());
export const updateCardio3DFailure = createAction("[Cardio3D] Update Cardio3D Failure", props<{ reason: string }>());

export const createCardio3D = createAction("[Cardio3D] Cardio3D Create", props<{ cardio3D: any }>());
export const createCardio3DSuccess = createAction("[Cardio3D] Create Cardio3D Success", props<{ cardio3D: any }>());
export const createCardio3DFailure = createAction("[Cardio3D] Create Cardio3D Failure", props<{ reason: string }>());

export const deleteCardio3D = createAction("[Cardio3D] Cardio3D Delete", props<{ cardio3D: any }>());
export const deleteCardio3DSuccess = createAction("[Cardio3D] Delete Cardio3D Success", props<{ cardio3DId: any }>());
export const deleteCardio3DFailure = createAction("[Cardio3D] Delete Cardio3D Failure", props<{ reason: string }>());

export const getCardio3D = createAction("[Cardio3D] Cardio3D get", props<{ cardio3D: any }>());
export const getCardio3DSuccess = createAction("[Cardio3D] get Cardio3D Success", props<{ cardio3D: any }>());
export const getCardio3DFailure = createAction("[Cardio3D] get Cardio3D Failure", props<{ reason: string }>());