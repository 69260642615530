import { createAction, props } from "@ngrx/store";
import { Dicom} from "../../services/api";
import { DicomControllerGetManyDicomRequestParams } from "../../services/api/api/dicom.serviceInterface";

export const loadManyDicom = createAction("[Dicom] Load Many Dicom", props<DicomControllerGetManyDicomRequestParams>());
export const loadManyDicomSuccess = createAction("[Dicom] Load Many Dicom Success", props<{ manyDicom: any, filtered: boolean }>());
export const loadManyDicomFailure = createAction("[Dicom] Load Many Dicom Failure");

export const loadDicom = createAction("[Dicom] Load Dicom", props<{ dicomId: string }>());
export const loadDicomSuccess = createAction("[Dicom] Load Dicom Success", props<{ dicom: Dicom }>());
export const loadDicomFailure = createAction("[Dicom] Load Dicom Failure");

export const updateDicom = createAction("[Dicom] Dicom Update", props<{ dicom: any }>());
export const updateDicomSuccess = createAction("[Dicom] Update Dicom Success", props<{ dicom: any }>());
export const updateDicomFailure = createAction("[Dicom] Update Dicom Failure", props<{ reason: string }>());

export const createDicom = createAction("[Dicom] Dicom Create", props<{ dicom: any }>());
export const createDicomSuccess = createAction("[Dicom] Create Dicom Success", props<{ dicom: any }>());
export const createDicomFailure = createAction("[Dicom] Create Dicom Failure", props<{ reason: string }>());

export const deleteDicom = createAction("[Dicom] Dicom Delete", props<{ dicom: any }>());
export const deleteDicomSuccess = createAction("[Dicom] Delete Dicom Success", props<{ dicomId: any }>());
export const deleteDicomFailure = createAction("[Dicom] Delete Dicom Failure", props<{ reason: string }>());

export const getDicom = createAction("[Dicom] Dicom get", props<{ dicom: any }>());
export const getDicomSuccess = createAction("[Dicom] get Dicom Success", props<{ dicom: any }>());
export const getDicomFailure = createAction("[Dicom] get Dicom Failure", props<{ reason: string }>());