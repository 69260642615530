import { Cardio3DCategory } from "../../services/api/model/cardio3DCategory";

export const persistedKeys = ["total","manyCardio3DCategories"];

export interface ManyCardio3DCategories {
  [title: string]: Cardio3DCategory;
}

export interface Cardio3DCategoryState {
  manyCardio3DCategories: ManyCardio3DCategories;
  loading: boolean;
  total: number;
}

export const initialState: Cardio3DCategoryState = {
  manyCardio3DCategories: {},
  loading: false,
  total: 0,
};
