import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as EcgCategoryActions from "./actions";
import { EcgCategoryService } from "../../services/api/api/ecgCategory.service";

@Injectable()
export class EcgCategoryEffects {
  public loadManyEcgCategories$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcgCategoryActions.loadManyEcgCategories),
      mergeMap(async ({ page, limit }) => {
        try {
          const result : any = await this.ecgCategoryService.ecgCategoryControllerGetManyEcgCategories({ page, limit}); 
          return EcgCategoryActions.loadManyEcgCategoriesSuccess({ manyEcgCategories: result });
        } catch (error) {
          console.error(error)
          return EcgCategoryActions.loadManyEcgCategoriesFailure();
        }
      }),
    ),
  );

  constructor(private actions$: Actions, private ecgCategoryService: EcgCategoryService) {}
}
