import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import * as RealCasesCategoryActions from "./actions";
import { RealCasesCategoryService } from "../../services/api/api/realCasesCategory.service";

@Injectable()
export class RealCasesCategoryEffects {
  public loadManyRealCasesCategories$: Observable<any> = createEffect(() =>
    this.actions$.pipe(

      ofType(RealCasesCategoryActions.loadManyRealCasesCategories),
      mergeMap(async ({ page, limit }) => {
        try {
          const result : any = await this.realCasesCategoryService.realCasesCategoryControllerGetManyRealCasesCategories({ page, limit}); 
          return RealCasesCategoryActions.loadManyRealCasesCategoriesSuccess({ manyRealCasesCategories: result });
        } catch (error) {
          console.error(error)
          return RealCasesCategoryActions.loadManyRealCasesCategoriesFailure();
        }
      }),
    ),
  );
  constructor(private actions$: Actions, public realCasesCategoryService: RealCasesCategoryService) {}
}
