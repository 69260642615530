import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as EcgActions from "./actions";
import { EcgService } from "../../services/api";
import * as FromEcg from "../ecg/selectors";
import { Store } from "@ngrx/store";
import { RootState } from "..";

@Injectable()
export class EcgEffects {
  public loadManyEcg$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      
      ofType(EcgActions.loadManyEcg),
      mergeMap(async ({ page, limit, filters }) => {
        try {
          const result : any = await this.ecgService.ecgControllerGetManyEcg({ page, limit, filters}); 
          return EcgActions.loadManyEcgSuccess({ manyEcg: result, filtered: filters.length != 0 });
        } catch (error) {
          console.error(error)
          return EcgActions.loadManyEcgFailure();
        }
      }),
    ),
  );

  public updateEcg$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcgActions.updateEcg),
      withLatestFrom(this.store.select(FromEcg.selectID)),
      mergeMap(async (action) => {
        const ecg = action[0].ecg; 
        const uid = action[0].uid; 
        try {
          const result: any = await this.ecgService.ecgControllerUpdateOne({
            ecg : ecg,
            uid: uid
          });
          return EcgActions.updateEcgSuccess({ ecg: result });
        } catch (error: any) {
          console.error(error);
          return EcgActions.updateEcgFailure({ reason: error.message });
        }
      })
    )
  );

  public createEcg$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcgActions.createEcg),
      withLatestFrom(this.store.select(FromEcg.selectID)),
      mergeMap(async (action) => {
        const ecg = action[0].ecg; 
        const uid = action[0].uid;
        try {
          const result: any = await this.ecgService.ecgControllerCreateEcg({
            ecg : ecg,
            uid : uid
          });
          return EcgActions.createEcgSuccess({ ecg: result });
        } catch (error: any) {
          console.error(error);
          return EcgActions.createEcgFailure({ reason: error.message });
        }
      })
    ),
  );

  public deleteEcg$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcgActions.deleteEcg),
      withLatestFrom(this.store.select(FromEcg.selectID)),
      mergeMap(async (action) => {
        const ecg = action[0].ecg;
        try {
          const result: any = await this.ecgService.ecgControllerDeleteEcg({
            ecg : ecg,
          });
          return EcgActions.deleteEcgSuccess({ ecgId: result.id });
        } catch (error: any) {
          console.error(error);
          return EcgActions.deleteEcgFailure({ reason: error.message });
        }
      })
    ),
  );

  public getEcg$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EcgActions.getEcg),
      withLatestFrom(this.store.select(FromEcg.selectID)),
      mergeMap(async (action) => {
        const ecg = action[0].ecg; 
        try {
          const result: any = await this.ecgService.ecgControllerGetEcg({
            ecg : ecg,
          });
          return EcgActions.getEcgSuccess({ ecg: result });
        } catch (error: any) {
          console.error(error);
          return EcgActions.getEcgFailure({ reason: error.message });
        }
      })
    ),
  );
  
  constructor(private actions$: Actions, private ecgService: EcgService,private store: Store<RootState>) {}
}
