import { createAction, props } from "@ngrx/store";
import { RealCasesCategoryControllerGetManyRealCasesCategoriesRequestParams } from 
"../../services/api/api/realCasesCategory.serviceInterface";
import { RealCasesCategory } from "../../services/api/model/realCasesCategory";

export const loadManyRealCasesCategories = createAction("[RealCases Category] Load Many realCases categories", props<RealCasesCategoryControllerGetManyRealCasesCategoriesRequestParams>());
export const loadManyRealCasesCategoriesSuccess = createAction("[RealCases Category] Load Many realCases categories Success", props<{ manyRealCasesCategories: Array<RealCasesCategory> }>());
export const loadManyRealCasesCategoriesFailure = createAction("[RealCases Category] Load Many realCases categories Failure");

export const loadRealCasesCategory = createAction("[RealCases Category] Load RealCases Category", props<{ realCasesCategoryId: string }>());
export const loadRealCasesCategorySuccess = createAction("[RealCases Category] Load RealCases Category Success", props<{ realCasesCategory: RealCasesCategory }>());
export const loadRealCasesCategoryFailure = createAction("[RealCases Category] Load RealCases Category Failure");