import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { PAGE_LIMIT } from "../../constants";
import { Dicom } from "../../services/api";
import { RootState } from "..";
import { ManyDicom } from "./state";

export const selectFeature = (state: RootState): ManyDicom => state.dicom.manyDicom;

export const selectID = (state: RootState): any => state.dicom.id;

export const selectCurrentDicom = (state: RootState): any => state.dicom.currentDicom;

export const selectAll = createSelector(selectFeature, (manyDicom: ManyDicom) => Object.values(manyDicom).filter(Boolean));

export const selectPaged = createSelector(selectAll, (dicom: Dicom[], page: number) =>
  dicom
    .sort((a: Dicom, b: Dicom) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA.getTime() < dateB.getTime()) return 1;
      if (dateA.getTime() > dateB.getTime()) return -1;
      return 0;
    })
    .slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT),
);

export const selectOne = createSelector(selectAll, (manyDicom: Dicom[], id: string) => manyDicom.find(dicom => dicom.id === id));

export const selectLoading = (state: RootState): boolean => state.dicom.loading;

export const selectTotal = (state: RootState): number => state.dicom.total;

export const selectRelatedDicom = (state: RootState): ManyDicom => state.dicom.manyDicom;

export const selectRelatedDicomArray = (state: RootState): Dicom[] => state.dicom.manyDicomArray;

export const selectArrayFilteredDicom = (state: RootState): Dicom[] => state.dicom.manyDicomArray;

export const selectFilteredDicom = (categories: string[]):
MemoizedSelector<RootState, Dicom[], DefaultProjectorFn<Dicom[]>> =>
  createSelector(selectRelatedDicom, (news: ManyDicom) =>{
    let val : Dicom[] = Object.values(news); 
    let dicomFiltered : Dicom[] = []; 
    val.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          dicomFiltered.push(element);
        }
      });
    });   
    return dicomFiltered;
  }  
);

export const selectFilteredDicomArray = (categories: string[]):
MemoizedSelector<RootState, Dicom[], DefaultProjectorFn<Dicom[]>> =>
  createSelector(selectRelatedDicomArray, (dicom: Dicom[]) =>{
    let dicomFiltered : Dicom[] = [];        
    dicom.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          dicomFiltered.push(element);
        }
      });
    }); 
    return dicomFiltered;
  }  
);

export const selectFilteredSearchDicomArray = (text: string): 
  MemoizedSelector<RootState, Dicom[], DefaultProjectorFn<Dicom[]>> =>
  createSelector(selectRelatedDicomArray, (dicom: Dicom[]) =>{
    let dicomFiltered : Dicom[] = [];        
    dicom.forEach(element => {
      if(element.title.toUpperCase().includes(text)){
        dicomFiltered.push(element);
      }
    });   
    return dicomFiltered;
  }   
);