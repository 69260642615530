import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as Cardio3DCategoryActions from "./actions";
import { Cardio3DCategory } from "../../services/api/model/cardio3DCategory";

export const cardio3DCategoryReducer = createReducer(
  initialState,

  on(Cardio3DCategoryActions.loadManyCardio3DCategories, Cardio3DCategoryActions.loadCardio3DCategory, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(Cardio3DCategoryActions.loadManyCardio3DCategoriesFailure, Cardio3DCategoryActions.loadCardio3DCategoryFailure, state => ({
    ...state,
    loading: false,
  })),

  on(Cardio3DCategoryActions.loadManyCardio3DCategoriesSuccess, (state, { manyCardio3DCategories }) => {
    const newCardio3DCategories: { [key: string]: Cardio3DCategory } = {};
    manyCardio3DCategories.forEach((cardio3DCategory: Cardio3DCategory) => {
      newCardio3DCategories[cardio3DCategory.title!] = cardio3DCategory;
    });
    return {
      ...state,
      manyCardio3DCategories: {
        ...newCardio3DCategories,
      },
      totalManyCardio3DCategories: manyCardio3DCategories.length,
      loading: false,
    };
  }),

  on(Cardio3DCategoryActions.loadCardio3DCategorySuccess, (state, { cardio3DCategory }) => {
    return {
      ...state,
      manyCardio3DCategories: {
        ...state.manyCardio3DCategories,
        [cardio3DCategory.title!]: cardio3DCategory,
      },
      loading: false,
    };
  })
);
