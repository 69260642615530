import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { PAGE_LIMIT } from "../../constants";
import { Ecg } from "../../services/api";
import { RootState } from "..";
import { ManyEcg } from "./state";

export const selectFeature = (state: RootState): ManyEcg => state.ecg.manyEcg;
export const selectID = (state: RootState): any => state.ecg.id;

export const selectAll = createSelector(selectFeature, (manyEcg: ManyEcg) => Object.values(manyEcg).filter(Boolean));

export const selectPaged = createSelector(selectAll, (ecg: Ecg[], page: number) =>
  ecg
    .sort((a: Ecg, b: Ecg) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA.getTime() < dateB.getTime()) return 1;
      if (dateA.getTime() > dateB.getTime()) return -1;
      return 0;
    })
    .slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT),
);

export const selectOne = createSelector(selectAll, (manyEcg: Ecg[], id: string) => manyEcg.find(ecg => ecg.id === id));

export const selectLoading = (state: RootState): boolean => state.ecg.loading;

export const selectCurrentEcg = (state: RootState): any => state.ecg.currentEcg;

export const selectTotal = (state: RootState): number => {return state.ecg.total};

export const selectRelatedEcg = (state: RootState): ManyEcg => state.ecg.manyEcg;

export const selectRelatedEcgArray = (state: RootState): Ecg[] => state.ecg.manyEcgArray;

export const selectFilteredEcg = (categories: string[]):
MemoizedSelector<RootState, Ecg[], DefaultProjectorFn<Ecg[]>> =>
  createSelector(selectRelatedEcg, (ecg: ManyEcg) =>{
    let val : Ecg[] = Object.values(ecg); 
    let ecgFiltered : Ecg[] = []; 
    val.forEach(element => {
      categories.forEach(category=>{
        element.category.forEach((elementCategory : string) => {
          if(elementCategory.toUpperCase() === category.toUpperCase().split(" ").join("_")){
            ecgFiltered.push(element);
          }
        });
      });
    });   
    return ecgFiltered;
  }  
);

export const selectFilteredEcgArray = (categories: string[]):
MemoizedSelector<RootState, Ecg[], DefaultProjectorFn<Ecg[]>> =>
  createSelector(selectRelatedEcgArray, (ecg: Ecg[]) =>{
    let ecgFiltered : Ecg[] = [];        
    ecg.forEach(element => {
      categories.forEach(category=>{
        if(element.category==undefined){
          console.log(element)
        }
        element.category.forEach((elementCategory : string) => {
          if(elementCategory.toUpperCase() === category.toUpperCase().split(" ").join("_")){
            ecgFiltered.push(element);
          }
        });        
      });
    });   
    return ecgFiltered;
  }  
);


export const selectFilteredSearchEcgArray = (text: string): 
  MemoizedSelector<RootState, Ecg[], DefaultProjectorFn<Ecg[]>> =>
  createSelector(selectRelatedEcgArray, (ecg: Ecg[]) =>{
    let ecgFiltered : Ecg[] = [];        
    ecg.forEach(element => {
      if(element.title.toUpperCase().includes(text)){
        ecgFiltered.push(element);
      }
    });   
    return ecgFiltered;
  }   
  );
