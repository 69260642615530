import { Development } from "../../services/api";

export const persistedKeys = ["total","manyDevelopment"];

export interface ManyDevelopment {
  [id: string]: Development;
}

export interface DevelopmentState {
  currentDevelopment: any;
  manyDevelopment: ManyDevelopment;
  manyDevelopmentArray: Development[];
  loading: boolean;
  total: number;
  id:string;
  likes: number,
  dislikes: number,
  views: number
}

export const initialState: DevelopmentState = {
  currentDevelopment:{},
  manyDevelopment: {},
  manyDevelopmentArray: [],
  loading: false,
  total: 0,
  id:"",
  likes: 0,
  dislikes: 0,
  views: 0
};
