import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as ScienceInsightsActions from "./actions";
import { ScienceInsightsService } from "../../services/api";
import * as FromScienceInsights from "../scienceInsights/selectors";
import { Store } from "@ngrx/store";
import { RootState } from "..";

@Injectable()
export class ScienceInsightsEffects {
  public loadManyScienceInsights$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      
      ofType(ScienceInsightsActions.loadManyScienceInsights),
      mergeMap(async ({ page, limit, filters }) => {
        try {
          const result : Observable<any> = await this.scienceInsightsService.scienceInsightsControllerGetManyScienceInsights({ page, limit, filters });
          return ScienceInsightsActions.loadManyScienceInsightsSuccess({ manyScienceInsights: result, filtered: filters.length != 0 });
        } catch (error) {
          console.error(error)
          return ScienceInsightsActions.loadManyScienceInsightsFailure();
        }
      }),
    ),
  );

  public updateScienceInsights$: Observable<any> = createEffect(() =>
  this.actions$.pipe(
    ofType(ScienceInsightsActions.updateScienceInsights),
    withLatestFrom(this.store.select(FromScienceInsights.selectID)),
    mergeMap(async (action) => {
      const scienceInsights = action[0].scienceInsights; 
      try {
        const result: any = await this.scienceInsightsService.scienceInsightsControllerUpdateOne({
          scienceInsights : scienceInsights,
        });
        return ScienceInsightsActions.updateScienceInsightsSuccess({ scienceInsights: result });
      } catch (error: any) {
        console.error(error);
        return ScienceInsightsActions.updateScienceInsightsFailure({ reason: error.message });
      }
    })
  )
);

public createScienceInsights$: Observable<any> = createEffect(() =>
  this.actions$.pipe(
    ofType(ScienceInsightsActions.createScienceInsights),
    withLatestFrom(this.store.select(FromScienceInsights.selectID)),
    mergeMap(async (action) => {
      const scienceInsights = action[0].scienceInsights; 
      try {
        const result: any = await this.scienceInsightsService.scienceInsightsControllerCreateScienceInsights({
          scienceInsights : scienceInsights,
        });
        return ScienceInsightsActions.createScienceInsightsSuccess({ scienceInsights: result });
      } catch (error: any) {
        console.error(error);
        return ScienceInsightsActions.createScienceInsightsFailure({ reason: error.message });
      }
    })
  ),
);

public deleteScienceInsights$: Observable<any> = createEffect(() =>
  this.actions$.pipe(
    ofType(ScienceInsightsActions.deleteScienceInsights),
    withLatestFrom(this.store.select(FromScienceInsights.selectID)),
    mergeMap(async (action) => {
      const scienceInsights = action[0].scienceInsights; 
      try {
        const result: any = await this.scienceInsightsService.scienceInsightsControllerDeleteScienceInsights({
          scienceInsights : scienceInsights,
        });
        return ScienceInsightsActions.deleteScienceInsightsSuccess({ scienceInsightsId: result.id });
      } catch (error: any) {
        console.error(error);
        return ScienceInsightsActions.deleteScienceInsightsFailure({ reason: error.message });
      }
    })
  ),
);

public getScienceInsights$: Observable<any> = createEffect(() =>
  this.actions$.pipe(
    ofType(ScienceInsightsActions.getScienceInsights),
    withLatestFrom(this.store.select(FromScienceInsights.selectID)),
    mergeMap(async (action) => {
      const scienceInsights = action[0].scienceInsights; 
      try {
        const result: any = await this.scienceInsightsService.scienceInsightsControllerGetScienceInsights({
          scienceInsights : scienceInsights,
        });
        return ScienceInsightsActions.getScienceInsightsSuccess({ scienceInsights: result });
      } catch (error: any) {
        console.error(error);
        return ScienceInsightsActions.getScienceInsightsFailure({ reason: error.message });
      }
    })
  ),
);

  constructor(private actions$: Actions, private scienceInsightsService: ScienceInsightsService, private store: Store<RootState>) {}
}
