import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { PAGE_LIMIT } from "../../constants";
import { ScienceInsights } from "../../services/api";
import { RootState } from "..";
import { ManyScienceInsights } from "./state";

export const selectFeature = (state: RootState): ManyScienceInsights => state.scienceInsights.manyScienceInsights;

export const selectID = (state: RootState): any => state.scienceInsights.id;

export const selectAll = createSelector(selectFeature, (manyScienceInsights: ManyScienceInsights) => Object.values(manyScienceInsights).filter(Boolean));

export const selectPaged = createSelector(selectAll, (scienceInsights: ScienceInsights[], page: number) =>
scienceInsights
    .sort((a: ScienceInsights, b: ScienceInsights) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA.getTime() < dateB.getTime()) return 1;
      if (dateA.getTime() > dateB.getTime()) return -1;
      return 0;
    })
    .slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT),
);

export const selectOne = createSelector(selectAll, (manyScienceInsights: ScienceInsights[], id: string) => 
manyScienceInsights.find(scienceInsights => scienceInsights.id === id));

export const selectLoading = (state: RootState): boolean => state.scienceInsights.loading;

export const selectTotal = (state: RootState): number => state.scienceInsights.total;

export const selectCurrentScienceInsights = (state: RootState): any => state.scienceInsights.currentScienceInsights;

export const selectRelatedScienceInsights = (state: RootState): ManyScienceInsights => state.scienceInsights.manyScienceInsights;

export const selectRelatedScienceInsightsArray = (state: RootState): ScienceInsights[] => state.scienceInsights.manyScienceInsightsArray;

export const selectArrayFilteredScienceInsights = (state: RootState): ScienceInsights[] => state.scienceInsights.manyScienceInsightsArray;

export const selectFilteredScienceInsights = (categories: string[]):
MemoizedSelector<RootState, ScienceInsights[], DefaultProjectorFn<ScienceInsights[]>> =>
  createSelector(selectRelatedScienceInsights, (news: ManyScienceInsights) =>{
    let val : ScienceInsights[] = Object.values(news); 
    let scienceInsightsFiltered : ScienceInsights[] = []; 
    val.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          scienceInsightsFiltered.push(element);
        }
      });
    });   
    return scienceInsightsFiltered;
  }  
);

export const selectFilteredScienceInsightsArray = (categories: string[]):
MemoizedSelector<RootState, ScienceInsights[], DefaultProjectorFn<ScienceInsights[]>> =>
  createSelector(selectRelatedScienceInsightsArray, (scienceInsights: ScienceInsights[]) =>{
    let scienceInsightsFiltered : ScienceInsights[] = [];        
    scienceInsights.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          scienceInsightsFiltered.push(element);
        }
      });
    }); 
    return scienceInsightsFiltered;
  }  
);

export const selectFilteredSearchScienceInsightsArray = (text: string): 
  MemoizedSelector<RootState, ScienceInsights[], DefaultProjectorFn<ScienceInsights[]>> =>
  createSelector(selectRelatedScienceInsightsArray, (scienceInsights: ScienceInsights[]) =>{
    let scienceInsightsFiltered : ScienceInsights[] = [];        
    scienceInsights.forEach(element => {
      if(element.title.toUpperCase().includes(text)){
        scienceInsightsFiltered.push(element);
      }
    });   
    return scienceInsightsFiltered;
  }   
  );