import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import * as SoftwareActions from "./actions";
import { SoftwareService } from "../../services/api/api/software.service";

@Injectable()
export class SoftwareEffects {

  public loadSoftware$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      
      ofType(SoftwareActions.loadSoftware),
      mergeMap(async (action) => {   
        const uid = action.uid; 
        try {
          const result : any = await this.softwareService.softwareControllerGetSoftware(uid); 
          return SoftwareActions.loadSoftwareSuccess({ software: result });
        } catch (error:any) {
          return SoftwareActions.loadSoftwareFailure({ reason: error.message });
        }
      }),
    ),
  );

  constructor(private actions$: Actions, private softwareService: SoftwareService) {}
}
