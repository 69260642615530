import { ImageCategory } from "../../services/api/model/imageCategory";

export const persistedKeys = ["total","manyImageCategories"];

export interface ManyImageCategories {
  [title: string]: ImageCategory;
}

export interface ImageCategoryState {
  manyImageCategories: ManyImageCategories;
  loading: boolean;
  total: number;
}

export const initialState: ImageCategoryState = {
  manyImageCategories: {},
  loading: false,
  total: 0,
};
