import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as CategoryActions from "./actions";
import { Category } from "../../services/api/model/category";

export const categoryReducer = createReducer(
  initialState,

  on(CategoryActions.loadManyCategories, CategoryActions.loadCategory, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(CategoryActions.loadManyCategoriesFailure, CategoryActions.loadCategoryFailure, state => ({
    ...state,
    loading: false,
  })),

  on(CategoryActions.loadManyCategoriesSuccess, (state, { manyCategories }) => {
    const newCategories: { [key: string]: Category } = {};
    manyCategories.forEach((category: Category) => {
      newCategories[category.title!] = category;
    });
    return {
      ...state,
      manyCategories: {
        ...newCategories,
      },
      totalManyCategories: manyCategories.length,
      loading: false,
    };
  }),

  on(CategoryActions.loadCategorySuccess, (state, { category }) => {
    return {
      ...state,
      manyCategories: {
        ...state.manyCategories,
        [category.title!]: category,
      },
      loading: false,
    };
  })
);
