import { createAction, props } from "@ngrx/store";
import { ManyEcg, Ecg } from "../../services/api";
import { EcgControllerGetManyEcgRequestParams } from "../../services/api/api/ecg.serviceInterface";

export const loadManyEcg = createAction("[Ecg] Load Many Ecg", props<EcgControllerGetManyEcgRequestParams>());
export const loadManyEcgSuccess = createAction("[Ecg] Load Many Ecg Success", props<{ manyEcg: any, filtered: any }>());
export const loadManyEcgFailure = createAction("[Ecg] Load Many Ecg Failure");

export const loadEcg = createAction("[Ecg] Load Ecg", props<{ ecgId: string }>());
export const loadEcgSuccess = createAction("[Ecg] Load Ecg Success", props<{ ecg: Ecg }>());
export const loadEcgFailure = createAction("[Ecg] Load Ecg Failure");

export const updateEcg = createAction("[Ecg] Ecg Update", props<{ ecg: any, uid: any }>());
export const updateEcgSuccess = createAction("[Ecg] Update Ecg Success", props<{ ecg: any }>());
export const updateEcgFailure = createAction("[Ecg] Update Ecg Failure", props<{ reason: string }>());

export const createEcg = createAction("[Ecg] Ecg Create", props<{ ecg: any, uid:any }>());
export const createEcgSuccess = createAction("[Ecg] Create Ecg Success", props<{ ecg: any }>());
export const createEcgFailure = createAction("[Ecg] Create Ecg Failure", props<{ reason: string }>());

export const deleteEcg = createAction("[Ecg] Ecg Delete", props<{ ecg: any }>());
export const deleteEcgSuccess = createAction("[Ecg] Delete Ecg Success", props<{ ecgId: any }>());
export const deleteEcgFailure = createAction("[Ecg] Delete Ecg Failure", props<{ reason: string }>());

export const getEcg = createAction("[Ecg] Ecg get", props<{ ecg: any }>());
export const getEcgSuccess = createAction("[Ecg] get Ecg Success", props<{ ecg: any }>());
export const getEcgFailure = createAction("[Ecg] get Ecg Failure", props<{ reason: string }>());