import { createAction, props } from "@ngrx/store";
import { DevelopmentCategoryControllerGetManyDevelopmentCategoriesRequestParams } from 
"../../services/api/api/developmentCategory.serviceInterface";
import { DevelopmentCategory } from "../../services/api/model/developmentCategory";

export const loadManyDevelopmentCategories = createAction("[Development Category] Load Many development categories", props<DevelopmentCategoryControllerGetManyDevelopmentCategoriesRequestParams>());
export const loadManyDevelopmentCategoriesSuccess = createAction("[Development Category] Load Many development categories Success", props<{ manyDevelopmentCategories: Array<DevelopmentCategory> }>());
export const loadManyDevelopmentCategoriesFailure = createAction("[Development Category] Load Many development categories Failure");

export const loadDevelopmentCategory = createAction("[Development Category] Load Development Category", props<{ developmentCategoryId: string }>());
export const loadDevelopmentCategorySuccess = createAction("[Development Category] Load Development Category Success", props<{ developmentCategory: DevelopmentCategory }>());
export const loadDevelopmentCategoryFailure = createAction("[Development Category] Load Development Category Failure");