import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as DevelopmentActions from "./actions";
import { Development } from "../../services/api";

export const developmentReducer = createReducer(
  initialState,

  on(DevelopmentActions.loadManyDevelopment, DevelopmentActions.loadDevelopment, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(DevelopmentActions.loadManyDevelopmentFailure, DevelopmentActions.loadDevelopmentFailure, state => ({
    ...state,
    loading: false,
  })),

  on(DevelopmentActions.loadManyDevelopmentSuccess, (state, { manyDevelopment, filtered }) => {
    const newDevelopment: { [key: string]: Development } = {};
    // Check query petition result
    if(manyDevelopment.development != undefined && manyDevelopment.development.length > 0){
    
      if(!filtered){
        let developmentArray = manyDevelopment.development[0];
        Object.keys(developmentArray).map((key: string) => {

          newDevelopment[key!] = developmentArray[key];
        });
      }else{
        manyDevelopment.development.forEach((development: Development) => {
          newDevelopment[development.id!] = development;
        });
      }
      let lastTotal = 0;
      if(manyDevelopment.isFiltered){
        if(manyDevelopment.development != undefined && manyDevelopment.development.length > 0){
          lastTotal = Object.keys(newDevelopment).length;
        }
      } else {
        lastTotal = state.total;
      }
      Object.keys(state.manyDevelopment).forEach(element => {
        if(newDevelopment[element] == undefined){
          newDevelopment[element] = state.manyDevelopment[element];
        }
      });
      let manyDevelopmentArrayAux : Development[] = [...state.manyDevelopmentArray];
      Object.values(newDevelopment).forEach(development => {
        let inserted : boolean = false;
        manyDevelopmentArrayAux.forEach(element => {
          if(element.id == development.id){
            inserted = true;
          }
        });
        if(!inserted){
          manyDevelopmentArrayAux = [...manyDevelopmentArrayAux, development]
        }
      });
      return {
        ...state,
        total: manyDevelopmentArrayAux.length,
        manyDevelopment: {
          ...newDevelopment
        },
        manyDevelopmentArray: manyDevelopmentArrayAux,
        loading: false,
      };
    } else {
      return {
        ...state,
        loading: false,
      };
    }
  }),

  on(DevelopmentActions.loadDevelopmentSuccess, (state, { development }) => {
    return {
      ...state,
      manyDevelopment: {
        ...state.manyDevelopment,
        [development.id!]: development,
      },
      loading: false,
    };
  }),

  on(DevelopmentActions.getDevelopmentSuccess, (state, { development }) => {
    return {
      ...state,
      currentDevelopment: development[0],
      loading: false,
    }
  }),

  on(DevelopmentActions.getDevelopmentFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(DevelopmentActions.updateDevelopmentSuccess, (state, { development}) => {
    return {
      ...state,
      loading: false,
      category: development.category,
      description: development.description,
      duration: development.duration,
      id: development.id,
      imagePreviewLink: development.imagePreviewLink,
      link: development.link,
      title: development.title,
      type: development.type,
      userId: development.userId,
    }
  }),

  on(DevelopmentActions.updateDevelopmentFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(DevelopmentActions.deleteDevelopmentSuccess, (state, { developmentId }) => {
    const manyDevelopment = Object.values(state.manyDevelopment).filter(developmen => developmen.id !== developmentId);
    const newDevelopment: { [key: string]: Development } = {};
    manyDevelopment.forEach((development: Development) => (newDevelopment[development.id!] = development));
    return {
      ...state, 
      manyDevelopmentArray: manyDevelopment,
      manyDevelopment: newDevelopment,
      loading: false,
    };  
  }),

  on(DevelopmentActions.deleteDevelopmentFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),
);
