/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { from, Observable }                                        from 'rxjs';

import { Configuration }                                     from '../configuration';
import { child, get, getDatabase, push, ref, remove, set, update } from 'firebase/database';
import { environment } from '../../../../environments/environment';
import { ImageCategoryControllerGetImageCategoryRequestParams, 
    ImageCategoryControllerGetManyImageCategoriesRequestParams, ImageCategoryServiceInterface } from './imageCategory.serviceInterface';
import { ImageCategory } from '../model/imageCategory';



@Injectable({
  providedIn: 'root'
})
export class ImageCategoryService implements ImageCategoryServiceInterface {

    //protected basePath = 'https://vrcardioexplorer-default-rtdb.firebaseio.com';
    protected basePath = environment.api;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        this.configuration.basePath = this.basePath;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public async imageCategoryControllerGetManyImageCategories(requestParameters: ImageCategoryControllerGetManyImageCategoriesRequestParams, 
        observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Promise<Observable<any>> {
        /*const dbRef = ref(getDatabase());
        let dbApiParts = environment.api.split("/");
        let dbType = dbApiParts[dbApiParts.length-1];
        let imageCategory : Array<ImageCategory> = [];
        return from(get(child(dbRef, dbType + `/imageCategories`)).then((snapshot) => {
            if (snapshot.exists()) {                
                snapshot.forEach(function(childSnapshot) {
                    let childKey : string = childSnapshot.key!;
                    let childVal : string = childSnapshot.val();
                    let childData : ImageCategory = {title:"", description: ""};
                    childData.title = childKey.split("_").join(" ");
                    childData.description = childVal;
                    imageCategory.push(childData)
                })
            }
        }).then(val => {
            return (imageCategory);
        }))*/
        let url = environment.getmanyinteractiveimagescategories;
        let result: any = await fetch(url)
        .then(response => response.json())
       .catch(error => {
            console.error('Error:', error);
        });
        return result;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public imageCategoryControllerGetImageCategory(requestParameters: ImageCategoryControllerGetImageCategoryRequestParams, 
        observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ImageCategory>{
        const dbRef = ref(getDatabase());
        let dbApiParts = environment.api.split("/");
        let dbType = dbApiParts[dbApiParts.length-1];
        let imageCategory : Array<ImageCategory> = [];
        return from(get(child(dbRef, dbType + `/imageCategories/${requestParameters.title}`)).then((snapshot) => {
            let value : ImageCategory = snapshot.val();
            imageCategory.push(value);
        }).then(val => {
            return (imageCategory[0])
        }))
    }
}
