import { ScienceInsights } from "../../services/api";

export const persistedKeys = ["total","manyScienceInsights"];

export interface ManyScienceInsights {
  [id: string]: ScienceInsights;
}

export interface ScienceInsightsState {
  currentScienceInsights: any;
  manyScienceInsights: ManyScienceInsights;
  manyScienceInsightsArray: ScienceInsights[];
  loading: boolean;
  total: number;
  id: string
  likes: number,
  dislikes: number,
  views: number
}

export const initialState: ScienceInsightsState = {
  currentScienceInsights: {},
  manyScienceInsights: {},
  manyScienceInsightsArray: [],
  loading: false,
  total: 0,
  id: "",
  likes: 0,
  dislikes: 0,
  views: 0
};
