import { Store } from "@ngrx/store";
import { RootState } from "./store";
import * as TeamActions from "../app/store/team/actions";
import { environment } from "../environments/environment";
import { Router } from "@angular/router";
import { trackUser } from "../app/services/api/trackUser";
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";

export const appInitializer = (http: HttpClient, store: Store<RootState>, router: Router, afAuth: AngularFireAuth): (() => Promise<void>) =>
  async (): Promise<void> => {
    store.dispatch(TeamActions.loadTeam());
    let userTrack: trackUser = new trackUser(http, store, router,afAuth);
    if (environment.trackUser) {
      userTrack.trackUserActivity();
    }
    if (environment.showUpdateTableMessage) {
      let getupdatetable = environment.getupdatetable;
      let requestOptions: RequestInit = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };

      try {
        let result = await fetch(getupdatetable, requestOptions);
        let table = await result.json();

        const actualDate = (new Date()).toLocaleString().trim();

        function parseDate(dateString:any) {
          var parts = dateString.split(/[\/, :]/);
          return new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5]);
        }
        
        var date1 = parseDate(actualDate);
        var date2 = parseDate(table.endTime);
        
        if (date1 < date2) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "The web will be under maintenance from " + table.startTime + " to " + table.endTime + " for " + table.reason,
            showConfirmButton: false,
            timer: 86400000,
            allowOutsideClick: false,
            allowEscapeKey: false,    
            allowEnterKey: false,
            backdrop: `rgba(0, 0, 0, 0.9)`
          }).then((result) => {
          });
        } 

      } catch (error) {
        console.log(error)
      }
    }
  };