import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as InteractiveImagesActions from "./actions";
import { InteractiveImages } from "../../services/api";

export const interactiveImagesReducer = createReducer(
  initialState,

  on(InteractiveImagesActions.loadManyInteractiveImages, InteractiveImagesActions.loadInteractiveImages, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(InteractiveImagesActions.loadManyInteractiveImagesFailure, InteractiveImagesActions.loadInteractiveImagesFailure, state => ({
    ...state,
    loading: false,
  })),

  on(InteractiveImagesActions.loadManyInteractiveImagesSuccess, (state, { manyInteractiveImages, filtered }) => {
    const newInteractiveImages: { [key: string]: InteractiveImages } = {};
    // Check query petition result
    if(manyInteractiveImages.interactiveimages != undefined && manyInteractiveImages.interactiveimages.length > 0){
      
      if(!filtered){
        let interactiveImagesArray = manyInteractiveImages.interactiveimages[0];
        Object.keys(interactiveImagesArray).map((key: string) => {

          newInteractiveImages[key!] = interactiveImagesArray[key];
        });
      }else{
        manyInteractiveImages.interactiveimages.forEach((interactiveImages: InteractiveImages) => {
          newInteractiveImages[interactiveImages.id!] = interactiveImages;
        });
      }

      let lastTotal = 0;
      if(manyInteractiveImages.isFiltered){
        if(manyInteractiveImages.interactiveimages != undefined && manyInteractiveImages.interactiveimages.length > 0){
          lastTotal = Object.keys(newInteractiveImages).length;
        }
      } else {
        lastTotal = state.total;
      }
      Object.keys(state.manyInteractiveImages).forEach(element => {
        if(newInteractiveImages[element] == undefined){
          newInteractiveImages[element] = state.manyInteractiveImages[element];
        }
      });
      let manyInteractiveImagesArrayAux : InteractiveImages[] = [...state.manyInteractiveImagesArray];
      Object.values(newInteractiveImages).forEach(interactiveImages => {
        let inserted : boolean = false;
        manyInteractiveImagesArrayAux.forEach(element => {
          if(element.id == interactiveImages.id){
            inserted = true;
          }
        });
        if(!inserted){
          manyInteractiveImagesArrayAux = [...manyInteractiveImagesArrayAux, interactiveImages]
        }
      });
      return {
        ...state,
        total: manyInteractiveImagesArrayAux.length,
        manyInteractiveImages: {
          ...newInteractiveImages
        },
        manyInteractiveImagesArray: manyInteractiveImagesArrayAux,
        loading: false,
      };
    } else {
      return {
        ...state,
        loading: false,
      };
    }
  }),

  on(InteractiveImagesActions.loadInteractiveImagesSuccess, (state, { interactiveImages }) => {
    return {
      ...state,
      manyInteractiveImages: {
        ...state.manyInteractiveImages,
        [interactiveImages.id!]: interactiveImages,
      },
      loading: false,
    };
  }),

  on(InteractiveImagesActions.getInteractiveImagesSuccess, (state, { interactiveImages }) => {
    return {
      ...state,
      currentInteractiveImages: interactiveImages[0],
      loading: false,
    }
  }),

  on(InteractiveImagesActions.getInteractiveImagesFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(InteractiveImagesActions.updateInteractiveImagesSuccess, (state, { interactiveImages}) => {
    return {
      ...state,
      loading: false,
      category: interactiveImages.category,
      createdAt: interactiveImages.createdAt,
      description: interactiveImages.description,
      descriptionField: interactiveImages.descriptionField,
      field: interactiveImages.field,
      height: interactiveImages.height,
      id: interactiveImages.id,
      image: interactiveImages.image,
      name: interactiveImages.name,
      title: interactiveImages.title,
      type: interactiveImages.type,
      width: interactiveImages.width,
      userId: interactiveImages.userId
    }
  }),

  on(InteractiveImagesActions.updateInteractiveImagesFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(InteractiveImagesActions.deleteInteractiveImagesSuccess, (state, { interactiveImagesId }) => {
    const manyInteractiveImages = Object.values(state.manyInteractiveImages).filter(interactiveImages => interactiveImages.id !== interactiveImagesId);
    const newInteractiveImages: { [key: string]: InteractiveImages } = {};
    manyInteractiveImages.forEach((interactiveImages: InteractiveImages) => (newInteractiveImages[interactiveImages.id!] = interactiveImages));

    return {
      ...state, 
      manyInteractiveImagesArray: manyInteractiveImages,
      manyInteractiveImages: newInteractiveImages,
      loading: false,
    };  
  }),

  on(InteractiveImagesActions.deleteInteractiveImagesFailure, (state, { reason }) => {
    console.log(reason)
    return {
      ...state,
      loading: true
    }
  }),
);
