import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import * as ImageCategoryActions from "./actions";
import { ImageCategoryService } from "../../services/api/api/imageCategory.service";

@Injectable()
export class ImageCategoryEffects {
  public loadManyImageCategories$: Observable<any> = createEffect(() =>
    this.actions$.pipe(

      ofType(ImageCategoryActions.loadManyImageCategories),
      mergeMap(async ({ page, limit }) => {
        try {
          const result : any = await this.imageCategoryService.imageCategoryControllerGetManyImageCategories({ page, limit}); 
          return ImageCategoryActions.loadManyImageCategoriesSuccess({ manyImageCategories: result });
        } catch (error) {
          console.error(error)
          return ImageCategoryActions.loadManyImageCategoriesFailure();
        }
      }),
    ),
  );
  constructor(private actions$: Actions, private imageCategoryService: ImageCategoryService) {}
}
