import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { PAGE_LIMIT } from "../../constants";
import { InteractiveImages } from "../../services/api";
import { RootState } from "..";
import { ManyInteractiveImages } from "./state";

export const selectFeature = (state: RootState): ManyInteractiveImages => state.interactiveImages.manyInteractiveImages;

export const selectID = (state: RootState): any => state.interactiveImages.id;

export const selectCurrentInteractiveImages = (state: RootState): any => state.interactiveImages.currentInteractiveImages;

export const selectAll = createSelector(selectFeature, (manyInteractiveImages: ManyInteractiveImages) => Object.values(manyInteractiveImages).filter(Boolean));

export const selectPaged = createSelector(selectAll, (interactiveImages: InteractiveImages[], page: number) =>
  interactiveImages
    .sort((a: InteractiveImages, b: InteractiveImages) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA.getTime() < dateB.getTime()) return 1;
      if (dateA.getTime() > dateB.getTime()) return -1;
      return 0;
    })
    .slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT),
);

export const selectOne = createSelector(selectAll, (manyInteractiveImages: InteractiveImages[], id: string) => manyInteractiveImages.find(interactiveImages => interactiveImages.id === id));

export const selectLoading = (state: RootState): boolean => state.interactiveImages.loading;

export const selectTotal = (state: RootState): number => state.interactiveImages.total;

export const selectRelatedInteractiveImages = (state: RootState): ManyInteractiveImages => state.interactiveImages.manyInteractiveImages;

export const selectRelatedInteractiveImagesArray = (state: RootState): InteractiveImages[] => state.interactiveImages.manyInteractiveImagesArray;

export const selectArrayFilteredInteractiveImages = (state: RootState): InteractiveImages[] => state.interactiveImages.manyInteractiveImagesArray;

export const selectFilteredInteractiveImages = (categories: string[]):
MemoizedSelector<RootState, InteractiveImages[], DefaultProjectorFn<InteractiveImages[]>> =>
  createSelector(selectRelatedInteractiveImages, (news: ManyInteractiveImages) =>{
    let val : InteractiveImages[] = Object.values(news); 
    let interactiveImagesFiltered : InteractiveImages[] = []; 
    val.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          interactiveImagesFiltered.push(element);
        }
      });
    });   
    return interactiveImagesFiltered;
  }  
);

export const selectFilteredInteractiveImagesArray = (categories: string[]):
MemoizedSelector<RootState, InteractiveImages[], DefaultProjectorFn<InteractiveImages[]>> =>
  createSelector(selectRelatedInteractiveImagesArray, (interactiveImages: InteractiveImages[]) =>{
    let interactiveImagesFiltered : InteractiveImages[] = [];    
    interactiveImages.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          interactiveImagesFiltered.push(element);
        }
      });
    });   
    return interactiveImagesFiltered;
  }  
);

export const selectFilteredSearchInteractiveImagesArray = (text: string): 
  MemoizedSelector<RootState, InteractiveImages[], DefaultProjectorFn<InteractiveImages[]>> =>
  createSelector(selectRelatedInteractiveImagesArray, (interactiveImages: InteractiveImages[]) =>{
    let interactiveImagesFiltered : InteractiveImages[] = [];        
    interactiveImages.forEach(element => {
      if(element.title.toUpperCase().includes(text)){
        interactiveImagesFiltered.push(element);
      }
    });   
    return interactiveImagesFiltered;
  }   
  );