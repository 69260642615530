import { Ecg } from "../../services/api";

export const persistedKeys = ["total","manyEcg", "currentEcg"];

export interface ManyEcg {
  [id: string]: Ecg;
}

export interface EcgState {
  currentEcg: any;
  manyEcg: ManyEcg;
  manyEcgArray: Ecg[];
  loading: boolean;
  total: number;
  category : string,
  content : string,
  csv : string,
  lowQualityImage : string,
  mediumQualityImage : string,
  highQualityImage : string,
  title : string,
  type : string,
  userId : string,
  id : string,
  likes: number,
  dislikes: number,
  views: number
}

export const initialState: EcgState = {
  currentEcg: {},
  manyEcg: {},
  manyEcgArray: [],
  loading: false,
  total: 0,
  category : "",
  content : "",
  csv : "",
  lowQualityImage : "",
  mediumQualityImage : "",
  highQualityImage : "",
  title : "",
  type : "",
  userId : "",
  id: "",
  likes: 0,
  dislikes: 0,
  views: 0
};
