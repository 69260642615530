import { createAction, props } from "@ngrx/store";
import { ImageCategoryControllerGetManyImageCategoriesRequestParams } from 
"../../services/api/api/imageCategory.serviceInterface";
import { ImageCategory } from "../../services/api/model/imageCategory";

export const loadManyImageCategories = createAction("[Image Category] Load Many image categories", props<ImageCategoryControllerGetManyImageCategoriesRequestParams>());
export const loadManyImageCategoriesSuccess = createAction("[Image Category] Load Many image categories Success", props<{ manyImageCategories: Array<ImageCategory> }>());
export const loadManyImageCategoriesFailure = createAction("[Image Category] Load Many image categories Failure");

export const loadImageCategory = createAction("[Image Category] Load Image Category", props<{ imageCategoryId: string }>());
export const loadImageCategorySuccess = createAction("[Image Category] Load Image Category Success", props<{ imageCategory: ImageCategory }>());
export const loadImageCategoryFailure = createAction("[Image Category] Load Image Category Failure");