/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ManyNews } from '../model/models';
import { News } from '../model/models';


import { Configuration }                                     from '../configuration';


export interface NewsControllerCreateNewsRequestParams {
    news: News;
}

export interface NewsControllerDeleteNewsRequestParams {
    news: News;
}

export interface NewsControllerGetManyNewsRequestParams {
    limit: number;
    page: number;
    filters: string[];
}

export interface NewsControllerGetNewsRequestParams {
    news: News;
}

export interface NewsControllerUpdateOneRequestParams {
    news: News;
}


export interface NewsServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
* @param requestParameters
     */
    newsControllerCreateNews(requestParameters: NewsControllerCreateNewsRequestParams, extraHttpRequestParams?: any): Promise<Observable<News>>;

    /**
     * 
     * 
* @param requestParameters
     */
    newsControllerDeleteNews(requestParameters: NewsControllerDeleteNewsRequestParams, extraHttpRequestParams?: any): Promise<Observable<{}>>;

    /**
     * 
     * 
* @param requestParameters
     */
    newsControllerGetManyNews(requestParameters: NewsControllerGetManyNewsRequestParams, extraHttpRequestParams?: any): Promise<Observable<any>>;

    /**
     * 
     * 
* @param requestParameters
     */
    newsControllerGetNews(requestParameters: NewsControllerGetNewsRequestParams, extraHttpRequestParams?: any): Promise<Observable<News>>;

    /**
     * 
     * 
* @param requestParameters
     */
    newsControllerUpdateOne(requestParameters: NewsControllerUpdateOneRequestParams, extraHttpRequestParams?: any): Promise<Observable<News>>;

}
