export const persistedKeys = ["total","software", "sectors"];

export interface SoftwareState {
  currentSoftware: any;
  windows_link: string,
  ios_link: string,
  linux_link: string,
  description: string,
  version: string,
  changes: any
}

export const initialState: SoftwareState = {
  currentSoftware: {},
  windows_link: "",
  ios_link: "",
  linux_link: "",
  description: "",
  version: "",
  changes: []
};
