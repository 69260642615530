import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { RootState } from "./store";
import * as FromUser from "./store/user/selectors";
import * as UserActions from "./store/user/actions";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import { PageRoutes } from "./enums";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public displayVideo : boolean = true;
  
  constructor(public router: Router, private store: Store<RootState>, private t: TranslateService) {}

  ngOnInit():void {
    const lastVideoIntroPlayed = localStorage.getItem('lastVideoIntroPlayed');
    if (!lastVideoIntroPlayed || (Date.now() - Number(lastVideoIntroPlayed)) >= Number.MAX_VALUE) { 
      this.displayVideo = true;
      localStorage.setItem('lastVideoIntroPlayed', Date.now().toString());
    }else{
      this.displayVideo = false;
    }
  }

  public logout(): void {
    if (confirm(this.t.instant("HEADER.confirmLogout"))) this.store.dispatch(UserActions.logout());
  }

}
