import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import * as CategoryActions from "./actions";
import { CategoryService } from "../../services/api/api/category.service";

@Injectable()
export class CategoryEffects {
  public loadManyCategories$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.loadManyCategories),
      mergeMap(async ({ page, limit }) => {
        try {
          const result : any = await this.categoryService.categoryControllerGetManyCategories({ page, limit}); 
          return CategoryActions.loadManyCategoriesSuccess({ manyCategories: result });
        } catch (error) {
          console.error(error)
          return CategoryActions.loadManyCategoriesFailure();
        }
      }),
    ),
  );

  constructor(private actions$: Actions, private categoryService: CategoryService) {}
}
