import { RealCasesCategory } from "../../services/api/model/realCasesCategory";

export const persistedKeys = ["total","manyRealCasesCategories"];

export interface ManyRealCasesCategories {
  [title: string]: RealCasesCategory;
}

export interface RealCasesCategoryState {
  manyRealCasesCategories: ManyRealCasesCategories;
  loading: boolean;
  total: number;
}

export const initialState: RealCasesCategoryState = {
  manyRealCasesCategories: {},
  loading: false,
  total: 0,
};
