import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { PAGE_LIMIT } from "../../constants";
import { Cardio3D } from "../../services/api";
import { RootState } from "..";
import { ManyCardio3D } from "./state";

export const selectFeature = (state: RootState): ManyCardio3D => state.cardio3D.manyCardio3D;

export const selectID = (state: RootState): any => state.cardio3D.id;

export const selectCurrentCardio3D = (state: RootState): any => state.cardio3D.currentCardio3D;

export const selectAll = createSelector(selectFeature, (manyCardio3D: ManyCardio3D) => 
  Object.values(manyCardio3D).filter(Boolean));

export const selectPaged = createSelector(selectAll, (cardio3D: Cardio3D[], page: number) =>
  cardio3D
    .sort((a: Cardio3D, b: Cardio3D) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA.getTime() < dateB.getTime()) return 1;
      if (dateA.getTime() > dateB.getTime()) return -1;
      return 0;
    })
    .slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT),
);

export const selectOne = createSelector(selectAll, (manyCardio3D: Cardio3D[], id: string) => 
  manyCardio3D.find(cardio3D => cardio3D.id === id));

export const selectLoading = (state: RootState): boolean => state.cardio3D.loading;

export const selectTotal = (state: RootState): number => {return state.cardio3D.total};

export const selectRelatedCardio3D = (state: RootState): ManyCardio3D => state.cardio3D.manyCardio3D;

export const selectRelatedCardio3DArray = (state: RootState): Cardio3D[] => state.cardio3D.manyCardio3DArray;

export const selectArrayFilteredCardio3D = (state: RootState): Cardio3D[] => state.cardio3D.manyCardio3DArray;

export const selectFilteredCardio3D = (categories: string[]):
MemoizedSelector<RootState, Cardio3D[], DefaultProjectorFn<Cardio3D[]>> =>
  createSelector(selectRelatedCardio3D, (cardio3D: ManyCardio3D) =>{
    let val : Cardio3D[] = Object.values(cardio3D); 
    let cardio3DFiltered : Cardio3D[] = []; 
    val.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          cardio3DFiltered.push(element);
        }
      });
    });   
    return cardio3DFiltered;
  }  
);

export const selectFilteredCardio3DArray = (categories: string[]):
MemoizedSelector<RootState, Cardio3D[], DefaultProjectorFn<Cardio3D[]>> =>
  createSelector(selectRelatedCardio3DArray, (cardio3D: Cardio3D[]) =>{
    let cardio3DFiltered : Cardio3D[] = [];        
    cardio3D.forEach(element => {
      categories.forEach(category=>{
        if(element.category.toUpperCase() === category.toUpperCase().split(" ").join("_")){
          cardio3DFiltered.push(element);
        }
      });
    });   
    return cardio3DFiltered;
  }  
);


export const selectFilteredSearchCardio3DArray = (text: string): 
  MemoizedSelector<RootState, Cardio3D[], DefaultProjectorFn<Cardio3D[]>> =>
  createSelector(selectRelatedCardio3DArray, (cardio3D: Cardio3D[]) =>{
    let cardio3DFiltered : Cardio3D[] = [];        
    cardio3D.forEach(element => {
      if(element.title.toUpperCase().includes(text)){
        cardio3DFiltered.push(element);
      }
    });   
    return cardio3DFiltered;
  }   
  );
