import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as NewsActions from "./actions";
import { News } from "../../services/api";

export const newsReducer = createReducer(
  initialState,

  on(NewsActions.loadManyNews, NewsActions.loadNews, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(NewsActions.loadManyNewsFailure, NewsActions.loadNewsFailure, state => ({
    ...state,
    loading: false,
  })),

  on(NewsActions.loadManyNewsSuccess, (state, { manyNews, filtered }) => {
    const newNews: { [key: string]: News } = {};
    // Check query petition result
    
    if(manyNews.news != undefined && manyNews.news.length > 0){
      if(!filtered){
        let newsArray = manyNews.news[0];
        Object.keys(newsArray).map((key: string) => {
  
          newNews[key!] = newsArray[key];
        });
      }else{
        manyNews.news.forEach((news: News) => {
          newNews[news.id!] = news;
        });
      }
      
        
      let lastTotal = 0;
      if(manyNews.isFiltered){
        if(manyNews.news != undefined && manyNews.news.length > 0){
          lastTotal = Object.keys(newNews).length;
        }
      } else {
        lastTotal = state.total;
      }
      Object.keys(state.manyNews).forEach(element => {
        if(newNews[element] == undefined){
          newNews[element] = state.manyNews[element];
        }
      });
      let manyNewsArrayAux : News[] = [...state.manyNewsArray];
      Object.values(newNews).forEach(news => {
        let inserted : boolean = false;
        manyNewsArrayAux.forEach(element => {
          if(element.id == news.id){
            inserted = true;
          }
        });
        if(!inserted){
          manyNewsArrayAux = [...manyNewsArrayAux, news]
        }
      });
      return {
        ...state,
        total: manyNewsArrayAux.length,
        manyNews: {
          ...newNews
        },
        manyNewsArray: manyNewsArrayAux,
        loading: false,
      };
    } else {
      return {
        ...state,
        loading: false,
      };
    }
  }),

  on(NewsActions.loadNewsSuccess, (state, { news }) => {
    return {
      ...state,
      manyNews: {
        ...state.manyNews,
        [news.id!]: news,
      },
      loading: false,
    };
  }),

  on(NewsActions.updateNewsSuccess, (state, { news}) => {
    return {
      ...state,
      loading: false,
      category : news.category,
      content : news.content,
      image : news.image,
      title : news.title,
      type : news.type,
      userId : news.userId,
      likes : news.likes,
      dislikes : news.dislikes,
    }
  }),

  on(NewsActions.updateNewsFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  
  on(NewsActions.getNewsSuccess, (state, { news }) => {
    return {
      ...state,
      currentNew: news[0],
      loading: false,
    }
  }),
  on(NewsActions.getNewsFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(NewsActions.deleteNewsSuccess, (state, { newsId }) => {
    const manyNews = Object.values(state.manyNews).filter(news => news.id !== newsId);
    const newNews: { [key: string]: News } = {};
    manyNews.forEach((news: News) => (newNews[news.id!] = news));
    return {
      ...state, 
      manyNewsArray: manyNews,
      manyNews: newNews,
      loading: false,
    };  
  }),

  on(NewsActions.deleteNewsFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),
);
