import { Category } from "../../services/api/model/category";

export const persistedKeys = ["total","manyCategories"];

export interface ManyCategories {
  [title: string]: Category;
}

export interface CategoryState {
  manyCategories: ManyCategories;
  loading: boolean;
  total: number;
}

export const initialState: CategoryState = {
  manyCategories: {},
  loading: false,
  total: 0,
};
