import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import * as DevelopmentCategoryActions from "./actions";
import { DevelopmentCategoryService } from "../../services/api/api/developmentCategory.service";

@Injectable()
export class DevelopmentCategoryEffects {
  public loadManyDevelopmentCategories$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DevelopmentCategoryActions.loadManyDevelopmentCategories),
      mergeMap(async ({ page, limit }) => {
        try {
          const result : any = await this.developmentCategoryService.developmentCategoryControllerGetManyDevelopmentCategories({ page, limit}); 
          return DevelopmentCategoryActions.loadManyDevelopmentCategoriesSuccess({ manyDevelopmentCategories: result });
        } catch (error) {
          console.error(error)
          return DevelopmentCategoryActions.loadManyDevelopmentCategoriesFailure();
        }
      }),
    ),
  );
  constructor(private actions$: Actions, public developmentCategoryService: DevelopmentCategoryService) {}
}
