import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { PageRoutes } from "../../enums";
import { environment } from "../../../environments/environment";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as FromUser from "../../store/user/selectors";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { RootState } from "../../store";
import { TranslateService } from "@ngx-translate/core";
import * as UserActions from "../../store/user/actions";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent{
  @Output() public navigate: EventEmitter<string> = new EventEmitter();
  
  public routes: typeof PageRoutes = PageRoutes;
  
  constructor(private router: Router, public afAuth: AngularFireAuth, private store: Store<RootState>, private t: TranslateService) {
    this.userData$ = this.store.select(FromUser.selectUserData);
  }

  public enablemarketingCookie : boolean = true;
  public enablefuncinalCookie : boolean = true;
  public enableperformanceCookie : boolean = true;
  public asepioImg : boolean = false;
  public userData$: Observable<string>;
  public userData: any | undefined;
  public marketingCookieBoolean : boolean = true;

  public ngOnInit():void{
    this.userData$.subscribe((val:any)=>{
      this.userData = val;
      
      setTimeout(() => {
        if(this.userData.id != undefined && this.userData.marketingCookie == undefined){
          (document.getElementById("marketingCookieSwitch")as HTMLInputElement).checked = true;
          this.openCookiesPopUp();
        }else{
          environment.trackUser = this.userData.marketingCookie;
          (document.getElementById("marketingCookieSwitch")as HTMLInputElement).checked = this.userData.marketingCookie;
        }
      }, 2000);
    })
  }

  public goToPage(page:string){
    let goToPage : string = "";
    switch (page.toUpperCase()) {
      case 'TWITTER':{
        goToPage = this.t.instant("FOOTER.TWITTER"); 
        break;
      }
      case 'INSTAGRAM':{
        goToPage = this.t.instant("FOOTER.INSTAGRAM"); 
        break;
      }
      case 'LINKEDIN':{
        goToPage = this.t.instant("FOOTER.LINKEDIN");  
        break;
      }
      case 'FACEBOOK':{
        goToPage = this.t.instant("FOOTER.FACEBOOK");  
        break;
      }
      case 'YOUTUBE':{
        goToPage = this.t.instant("FOOTER.YOUTUBE");  
        break;
      }
    }
    window.open(goToPage, '_blank');
  }

  public goToCollaboratorsPage(): void {
    this.router.navigate([this.routes.collaborators]);
  }

  public goToPrivacyPolicySection(): void {
    this.router.navigate([this.routes.privacypolicy]);
  }

  public goToLegalInformationSection(): void {
    this.router.navigate([this.routes.legalInformation]);
  }

  public openCookiesPopUp() : void{
    var modal = document.getElementById("cookiesPopUp")!;
    modal.style.display = "block";
  }

  public closeCookiesPopUp() : void {
    var modal = document.getElementById("cookiesPopUp")!;
    modal.style.display = "none";
    if(this.userData.id != ""){
      //this.updateUserState();
    }
  }

  public marketingCookie():void{
    if(this.enablemarketingCookie===true){
      this.enablemarketingCookie=false;
      environment.trackUser = false;
      this.marketingCookieBoolean = false;
     
      //console.log("desactivar marketing cookie");
    }else{
      this.enablemarketingCookie=true;
      environment.trackUser = true;
      this.marketingCookieBoolean = true;
      //console.log("activar marketing cookie");
    }
  }

  public funcionalCookie():void{
    if(this.enablefuncinalCookie===true){
      this.enablefuncinalCookie=false;
      //console.log("desactivar funcional cookie");
    }else{
      this.enablefuncinalCookie=true;
      //console.log("activar funcional cookie");
    }
  }

  public performanceCookie():void{
    if(this.enableperformanceCookie===true){
      this.enableperformanceCookie=false;
      //console.log("desactivar performace cookie");
    }else{
      this.enableperformanceCookie=true;
      //console.log("activar performace cookie");
    }
  }

  public updateUserState(){
    this.store.dispatch(
      UserActions.updateUser({
        newUserId: this.userData.id,
        update: "user",
        user: {
          createdAt: this.userData.createdAt,
          email: this.userData.email,
          id: this.userData.id,
          name: this.userData.name,
          organisation: this.userData.organisation,
          organisationRole: this.userData.organisationRole,
          myNews: this.userData.myNews,
          myRealCases: this.userData.myRealCases,
          myDevelopment: this.userData.myDevelopment,
          myCardio3d: this.userData.myCardio3d,
          myInteractiveImage: this.userData.myInteractiveImage,
          myEcg: this.userData.myEcg,
          myPatient: this.userData.myPatient,
          mySession: this.userData.mySessions,
          myActiveSession: this.userData.myActiveSession,
          myCartoCases: this.userData.myCartoCases,
          myDicom: this.userData.myDicom,
          image: this.userData.image,
          password: this.userData.password,
          surname: this.userData.surname,
          uid: this.userData.uid,
          webRole: this.userData.webRole,
          likes: this.userData.likes,
          dislikes: this.userData.dislikes,
          connectedDevices: this.userData.connectedDevices,
          deadline: this.userData.deadline,
          marketingCookie: this.marketingCookieBoolean,
          smsVerification: this.userData.smsVerification,
          processingSession: this.userData.processingSession,
          sessionState: this.userData.sessionState,
          phone: this.userData.phone
        },
      })
    );
  }
}
