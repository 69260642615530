import { RealCases } from "../../services/api";

export const persistedKeys = ["total","manyRealCases"];

export interface ManyRealCases {
  [id: string]: RealCases;
}

export interface RealCasesState {
  currentRealCase: any;
  manyRealCases: ManyRealCases;
  manyRealCasesArray: RealCases[];
  loading: boolean;
  total: number;
  id: string;
  likes: number,
  dislikes: number,
  views: number
}

export const initialState: RealCasesState = {
  currentRealCase : {},
  manyRealCases: {},
  manyRealCasesArray: [],
  loading: false,
  total: 0,
  id: "",
  likes: 0,
  dislikes: 0,
  views: 0
};
