import { Cardio3D } from "../../services/api";

export const persistedKeys = ["total","manyCardio3D"];

export interface ManyCardio3D {
  [id: string]: Cardio3D;
}

export interface Cardio3DState {
  currentCardio3D: any;
  manyCardio3D: ManyCardio3D;
  manyCardio3DArray: Cardio3D[];
  loading: boolean;
  total: number;
  id:string;
  userId: string;
  likes: number;
  dislikes: number;
  views: number;
}

export const initialState: Cardio3DState = {
  currentCardio3D: {},
  manyCardio3D: {},
  manyCardio3DArray: [],
  loading: false,
  total: 0,
  id:"",
  userId:"",
  likes: 0,
  dislikes: 0,
  views: 0
};
