import { TeamUser } from "../../services/api/model/teamUser";

export const persistedKeys = ["total","team", "sectors"];

export interface TeamState {
  team: Array<TeamUser>;
  total: number;
  sectors: Array<string>;
  loading: boolean;
}

export const initialState: TeamState = {
  team: [],
  sectors: [],
  loading: false,
  total: 0,
};
