import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as ImageCategoryActions from "./actions";
import { ImageCategory } from "../../services/api/model/imageCategory";

export const imageCategoryReducer = createReducer(
  initialState,

  on(ImageCategoryActions.loadManyImageCategories, ImageCategoryActions.loadImageCategory, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(ImageCategoryActions.loadManyImageCategoriesFailure, ImageCategoryActions.loadImageCategoryFailure, state => ({
    ...state,
    loading: false,
  })),

  on(ImageCategoryActions.loadManyImageCategoriesSuccess, (state, { manyImageCategories }) => {
    const newImageCategories: { [key: string]: ImageCategory } = {};
    manyImageCategories.forEach((imageCategory: ImageCategory) => {
      newImageCategories[imageCategory.title!] = imageCategory;
    });
    return {
      ...state,
      manyImageCategories: {
        ...newImageCategories,
      },
      totalManyImageCategories: manyImageCategories.length,
      loading: false,
    };
  }),

  on(ImageCategoryActions.loadImageCategorySuccess, (state, { imageCategory }) => {
    return {
      ...state,
      manyImageCategories: {
        ...state.manyImageCategories,
        [imageCategory.title!]: imageCategory,
      },
      loading: false,
    };
  })
);
