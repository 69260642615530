import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as EcgCategoryActions from "./actions";
import { EcgCategory } from "../../services/api/model/ecgCategory";

export const ecgCategoryReducer = createReducer(
  initialState,

  on(EcgCategoryActions.loadManyEcgCategories, EcgCategoryActions.loadEcgCategory, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(EcgCategoryActions.loadManyEcgCategoriesFailure, EcgCategoryActions.loadEcgCategoryFailure, state => ({
    ...state,
    loading: false,
  })),

  on(EcgCategoryActions.loadManyEcgCategoriesSuccess, (state, { manyEcgCategories }) => {
    
    const newEcgCategories: { [key: string]: EcgCategory } = {};
    manyEcgCategories.forEach((ecgCategory: EcgCategory) => {
      newEcgCategories[ecgCategory.title!] = ecgCategory;
    });
    return {
      ...state,
      manyEcgCategories: {
        ...newEcgCategories,
      },
      totalManyEcgCategories: manyEcgCategories.length,
      loading: false,
    };
  }),

  on(EcgCategoryActions.loadEcgCategorySuccess, (state, { ecgCategory }) => {
    return {
      ...state,
      manyEcgCategories: {
        ...state.manyEcgCategories,
        [ecgCategory.title!]: ecgCategory,
      },
      loading: false,
    };
  })
);
