import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { PageRoutes } from "../../enums";
import { RootState } from "../../store";
import * as FromUser from "../../store/user/selectors";
import * as UserActions from "../../store/user/actions";
import Swal from 'sweetalert2';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() public isUserLoggedIn?: boolean;

  @Output() public navigate: EventEmitter<string> = new EventEmitter();
  @Output() public logout: EventEmitter<void> = new EventEmitter();

  public routes: typeof PageRoutes = PageRoutes;
  public isVRCardioPage!: boolean;
  public isNewsPage!: boolean;
  public isDevelopmentPage!: boolean;
  public isCountdownPage!: boolean;
  public isScienceInsightsPage!: boolean;
  public isRealCases!: boolean;
  public isCartoCasesPage!: boolean;
  public isClinicalCasesPage!: boolean;
  public isInteractivePage!: boolean;
  public isCardio3DPage!: boolean;
  public isECGPage!: boolean;
  public isDicomPage!: boolean;
  public isDownloadSoftwarePage!: boolean;
  public isQuizPage!: boolean;
  public userName: string = "";


  public iconAdmin: string = "fa fa-id-badge";
  public iconLogout: string = "fa fa-power-off";
  public iconAccess: string = "fa fa-user-circle";
  public headerPageNews!: HTMLElement;
  public headerPageVRCardio!: HTMLElement;
  public headerPageDevelopment!: HTMLElement;
  public headerPageInteractive!: HTMLElement;
  public headerPageRealCases!: HTMLElement;
  public headerPageCardio3D!: HTMLElement;
  public headerPageECG!: HTMLElement;
  public headerPageScienceInsights!: HTMLElement;
  public headerPageDownloadSoftware!: HTMLElement;

  public userID$: Observable<string>;
  public userName$: Observable<string>;
  public isHomeAvailable: boolean = false;

  constructor(private router: Router, private t: TranslateService, private store: Store<RootState>) {
    this.userID$ = this.store.select(FromUser.selectUserID);
    this.userName$ = this.store.select(FromUser.selectUserName);

    router.events.subscribe((val) => {
      //Header style
      this.isVRCardioPage = router.url.length == 1;
      this.isNewsPage = router.url.includes(PageRoutes.news);
      this.isDevelopmentPage = router.url.includes(PageRoutes.development);
      this.isCountdownPage = router.url.includes(PageRoutes.countdown);
      this.isScienceInsightsPage = router.url.includes(PageRoutes.scienceInsights);
      this.isRealCases = (router.url.includes(PageRoutes.realCases) || router.url.includes(PageRoutes.cartoCases));
      this.isClinicalCasesPage = router.url.includes(PageRoutes.realCases);
      this.isCartoCasesPage = router.url.includes(PageRoutes.cartoCases);
      this.isInteractivePage = router.url.includes(PageRoutes.interactive);
      this.isCardio3DPage = router.url.includes(PageRoutes.cardio3d);
      this.isECGPage = router.url.includes(PageRoutes.ecg);
      this.isDownloadSoftwarePage = router.url.includes(PageRoutes.downloadsoftware);
      this.isDicomPage = router.url.includes(PageRoutes.dicom);
      this.isQuizPage = router.url.includes(PageRoutes.quiz);
    });
  }

  public ngOnInit(): void {
    this.headerPageNews = document.getElementById('header__tabs__text__news')!;
    this.headerPageVRCardio = document.getElementById('header__tabs__text__VRCardio')!;
    this.headerPageDevelopment = document.getElementById('header__tabs__text__development')!;
    this.headerPageInteractive = document.getElementById('header__tabs__text__interactive')!;
    this.headerPageScienceInsights = document.getElementById('header__tabs__text__scienceInsights')!;
    this.headerPageRealCases = document.getElementById('header__tabs__text__realCases')!;
    this.headerPageCardio3D = document.getElementById('header__tabs__text__cardio3D')!;
    this.headerPageECG = document.getElementById('header__tabs__text__ecg')!;
    this.headerPageDownloadSoftware = document.getElementById('header__tabs__text__downloadSoftware')!;
    this.userName$.subscribe((val: any) => {
      this.isUserLoggedIn = val;
      this.userName = val;
    })
    
    let flagEn : any= document.getElementById('header__dropdown__content__flag__en');
    let flagEs : any= document.getElementById('header__dropdown__content__flag__es');
    let flagAr : any= document.getElementById('header__dropdown__content__flag__ar');
    let currentFlagEs : any= document.getElementById('esCurrentFlagImage');
    let currentFlagEn : any= document.getElementById('enCurrentFlagImage');
    let currentFlagAr : any= document.getElementById('arCurrentFlagImage');
    let languageDropdown : any= document.getElementById('languageDropdown');
    languageDropdown.style.display = "none";
    let languageCode = this.t.defaultLang;
    switch(languageCode){
      case 'en':{
        flagEn.style.filter = "grayscale(0.5)";
        flagEs.style.filter = "grayscale(1)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "none";
        currentFlagEn.style.display = "flex";
        currentFlagAr.style.display = "none";
        currentFlagEn.style.filter = "grayscale(0.5)";
        break;
      }
      case 'es':{
        flagEn.style.filter = "grayscale(1)";
        flagEs.style.filter = "grayscale(0.5)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "flex";
        currentFlagEn.style.display = "none";
        currentFlagAr.style.display = "none";
        currentFlagEs.style.filter = "grayscale(0.5)";
        break;
      }
      case 'ar':{
        flagEn.style.filter = "grayscale(1)";
        flagEs.style.filter = "grayscale(0.5)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "none";
        currentFlagEn.style.display = "none";
        currentFlagAr.style.display = "flex";
        currentFlagAr.style.filter = "grayscale(0.5)";
        break;
      }
    }
    
  }

  public goToPage(page: string, user: string) {
    switch (page) {
      case "DEVELOPMENT": {
        this.router.navigate([this.routes.development]);
        break;
      }
      case "COUNTDOWN": {
        this.router.navigate([this.routes.countdown]);
        break;
      }
      case "VRCARDIO": {
        this.router.navigate([""]);
        break;
      }
      case "NEWS": {
        this.router.navigate([this.routes.news]);
        break;
      }
      case "DOWNLOADSOFTWARE": {
        this.router.navigate([this.routes.downloadsoftware]);
        break;
      }
      case "INTERACTIVE": {
        this.router.navigate([this.routes.interactive]);
        break;
      }
      case "CARDIO3D": {
        this.router.navigate([this.routes.cardio3d]);
        break;
      }
      case "ECG": {
        this.router.navigate([this.routes.ecg]);
        break;
      }
      case "SCIENCEINSIGHTS": {
        this.router.navigate([this.routes.scienceInsights]);
        break;
      }
      case "LOGIN": {
        this.router.navigate([this.routes.login]);
        break;
      }
      case "PROFILE": {
        if (user != undefined && user != null && user != "") {
          this.router.navigate([this.routes.profile]);
        } else {
          this.router.navigate([this.routes.login]);
        }
        break;
      }
      case "CLINICALCASES": {
        this.router.navigate([this.routes.realCases]);
        break;
      }
      case "CARTOCASES": {
        this.router.navigate([this.routes.cartoCases]);
        break;
      }
      case "DICOM": {
        this.router.navigate([this.routes.dicom]);
        break;
      }
      case "QUIZ": {
        this.router.navigate([this.routes.quiz]);
        break;
      }
      case "ATLAS": {
        let pageRoute: string = "https://cardiacanatomyatlas.com/atlas/";
        window.open(pageRoute, '_blank');
        break;
      }
    }
  }

  public navigateTo(page: string) {
    let pageRoute: string = "";
    switch (page.toUpperCase()) {
      case 'ATLAS': {
        pageRoute = "https://cardiacanatomyatlas.com/atlas/";
        break;
      }
      case 'MINIATLAS': {
        pageRoute = "https://cardiacanatomyatlas.com/miniatlas/";
        break;
      }
      case 'VIDEOS': {
        pageRoute = "https://cardiacanatomyatlas.com/videos/";
        break;
      }
      case 'TUTORIALS': {
        pageRoute = "https://cardiacanatomyatlas.com/our-tutorials/";
        break;
      }
      case 'CLINICALCASES': {
        pageRoute = "https://cardiacanatomyatlas.com/cases/";
        break;
      }
      case 'REFERENCES': {
        pageRoute = "https://cardiacanatomyatlas.com/our-references/anatomic-basis-for-ablation/";
        break;
      }
      case 'AOE': {
        pageRoute = "https://cardiacanatomyatlas.com/";
        break;
      }
    }
    window.open(pageRoute, '_blank');
  }

  public signOut() {
    Swal.fire({
      title: this.t.instant('HEADER.SIGNOUT'),
      text: this.t.instant('HEADER.SIGNOUTTEXT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8b8b8b',
      confirmButtonText: this.t.instant('HEADER.YES'),
      cancelButtonColor: '#8b8b8b',
      cancelButtonText: this.t.instant('HEADER.NO'),
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(UserActions.logout());
      }
    });
  }

  public changeLanguage(languageCode:string) {
    //console.log('Language changed to ' + languageCode);
    this.t.use(languageCode);
    this.t.setDefaultLang(languageCode);
    let flagEn : any= document.getElementById('header__dropdown__content__flag__en');
    let flagEs : any= document.getElementById('header__dropdown__content__flag__es');
    let flagAr : any= document.getElementById('header__dropdown__content__flag__ar');
    let currentFlagEs : any= document.getElementById('esCurrentFlagImage');
    let currentFlagEn : any= document.getElementById('enCurrentFlagImage');
    let currentFlagAr : any= document.getElementById('arCurrentFlagImage');
    let languageDropdown : any= document.getElementById('languageDropdown');
    languageDropdown.style.display = "none";
    switch(languageCode){
      case 'en':{
        flagEn.style.filter = "grayscale(0.5)";
        flagEs.style.filter = "grayscale(1)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "none";
        currentFlagEn.style.display = "flex";
        currentFlagAr.style.display = "none";
        currentFlagEn.style.filter = "grayscale(0.5)";
        break;
      }
      case 'es':{
        flagEn.style.filter = "grayscale(1)";
        flagEs.style.filter = "grayscale(0.5)";
        flagAr.style.filter = "grayscale(1)";
        currentFlagEs.style.display = "flex";
        currentFlagEn.style.display = "none";
        currentFlagAr.style.display = "none";
        currentFlagEs.style.filter = "grayscale(0.5)";
        break;
      }
      case 'ar':{
        flagEn.style.filter = "grayscale(1)";
        flagEs.style.filter = "grayscale(1)";
        flagAr.style.filter = "grayscale(0.5)";
        currentFlagEs.style.display = "none";
        currentFlagEn.style.display = "none";
        currentFlagAr.style.display = "flex";
        currentFlagAr.style.filter = "grayscale(0.5)";
        break;
      }
    }
  }

  public toggleDropdown() {
    let dropdownContent : any= document.getElementById('languageDropdown');
    let headerFlagSelection : any= document.getElementById('headerFlagSelection');
    this.hideOnClickOutside(headerFlagSelection)
    dropdownContent.style.display = (dropdownContent.style.display === 'flex') ? 'none' : 'flex';
  }

  public  hideOnClickOutside(element : any) {
    const outsideClickListener = (event:any) => {
      if (!element.contains(event.target) && this.isVisible(element)) {
        let languageDropdown : any= document.getElementById('languageDropdown');
        languageDropdown.style.display = 'none';
        removeClickListener();
      }
    }

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
    }

    document.addEventListener('click', outsideClickListener);
  }

  public isVisible(elem:any) : boolean{
    return !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length ); 
  } 
}
