import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as EcgActions from "./actions";
import { Ecg } from "../../services/api";

export const ecgReducer = createReducer(
  initialState,

  on(EcgActions.loadManyEcg, EcgActions.loadEcg, state => {
    return{
      ...state,
      loading: true,
    }
  }),

  on(EcgActions.loadManyEcgFailure, EcgActions.loadEcgFailure, state => ({
    ...state,
    loading: false,
  })),

  on(EcgActions.loadManyEcgSuccess, (state, { manyEcg, filtered }) => {
    const newEcg: { [key: string]: Ecg } = {};
    // Check query petition result
    
    if(manyEcg.ecg != undefined && manyEcg.ecg.length > 0){
      if(!filtered){
        let ecgArray = manyEcg.ecg[0];
        Object.keys(ecgArray).map((key: string) => {
  
          newEcg[key!] = ecgArray[key];
        });
      }else{
        manyEcg.ecg.forEach((ecg: Ecg) => {
          newEcg[ecg.id!] = ecg;
        });
      }
      
        
      let lastTotal = 0;
      if(manyEcg.isFiltered){
        if(manyEcg.ecg != undefined && manyEcg.ecg.length > 0){
          lastTotal = Object.keys(newEcg).length;
        }
      } else {
        lastTotal = state.total;
      }
      Object.keys(state.manyEcg).forEach(element => {
        if(newEcg[element] == undefined){
          newEcg[element] = state.manyEcg[element];
        }
      });
      let manyEcgArrayAux : Ecg[] = [...state.manyEcgArray];
      Object.values(newEcg).forEach(ecg => {
        let inserted : boolean = false;
        manyEcgArrayAux.forEach(element => {
          if(element.id == ecg.id){
            inserted = true;
          }
        });
        if(!inserted){
          manyEcgArrayAux = [...manyEcgArrayAux, ecg]
        }
      });
      return {
        ...state,
        total: manyEcgArrayAux.length,
        manyEcg: {
          ...newEcg
        },
        manyEcgArray: manyEcgArrayAux,
        loading: false,
      };
    } else {
      return {
        ...state,
        loading: false,
      };
    }
  }),

  on(EcgActions.loadEcgSuccess, (state, { ecg }) => {
    return {
      ...state,
      manyEcg: {
        ...state.manyEcg,
        [ecg.id!]: ecg,
      },
      loading: false,
    };
  }),

  on(EcgActions.updateEcgSuccess, (state, { ecg}) => {
    return {
      ...state,
      loading: false,
      category : ecg.category,
      content : ecg.content,
      csv : ecg.csv,
      lowQualityImage : ecg.lowQualityImage,
      mediumQualityImage : ecg.mediumQualityImage,
      highQualityImage : ecg.highQualityImage,
      title : ecg.title,
      type : ecg.type,
      userId : ecg.userId,
      likes : ecg.likes,
      dislikes : ecg.dislikes
    }
  }),

  on(EcgActions.updateEcgFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  
  on(EcgActions.getEcgSuccess, (state, { ecg }) => {
    return {
      ...state,
      currentEcg: ecg[0],
      loading: false,
    }
  }),
  on(EcgActions.getEcgFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(EcgActions.deleteEcgSuccess, (state, { ecgId }) => {
    const manyEcg = Object.values(state.manyEcg).filter(ecg => ecg.id !== ecgId);
    const newEcg: { [key: string]: Ecg } = {};
    manyEcg.forEach((ecg: Ecg) => (newEcg[ecg.id!] = ecg));
    return {
      ...state, 
      manyEcgArray: manyEcg,
      manyEcg: newEcg,
      loading: false,
    };  
  }),

  on(EcgActions.deleteEcgFailure, (state, { reason }) => {
    return {
      ...state,
      loading: true
    }
  }),
);
