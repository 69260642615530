/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ManyCardio3D } from '../model/models';
import { Cardio3D } from '../model/models';


import { Configuration }                                     from '../configuration';


export interface Cardio3DControllerCreateCardio3DRequestParams {
    cardio3D: Cardio3D;
}

export interface Cardio3DControllerDeleteCardio3DRequestParams {
    cardio3D: Cardio3D;
}

export interface Cardio3DControllerGetManyCardio3DRequestParams {
    limit: number;
    page: number;
    filters: string[];
}

export interface Cardio3DControllerGetCardio3DRequestParams {
    cardio3D: Cardio3D;
}

export interface Cardio3DControllerUpdateOneRequestParams {
    cardio3D: Cardio3D;
}


export interface Cardio3DServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
* @param requestParameters
     */
    cardio3DControllerCreateCardio3D(requestParameters: Cardio3DControllerCreateCardio3DRequestParams, extraHttpRequestParams?: any): Promise<Observable<Cardio3D>>;

    /**
     * 
     * 
* @param requestParameters
     */
    cardio3DControllerDeleteCardio3D(requestParameters: Cardio3DControllerDeleteCardio3DRequestParams, extraHttpRequestParams?: any): Promise<Observable<{}>>;

    /**
     * 
     * 
* @param requestParameters
     */
    cardio3DControllerGetManyCardio3D(requestParameters: Cardio3DControllerGetManyCardio3DRequestParams, extraHttpRequestParams?: any): Promise<Observable<any>>;

    /**
     * 
     * 
* @param requestParameters
     */
    cardio3DControllerGetCardio3D(requestParameters: Cardio3DControllerGetCardio3DRequestParams, extraHttpRequestParams?: any): Promise<Observable<Cardio3D>>;

    /**
     * 
     * 
* @param requestParameters
     */
    cardio3DControllerUpdateOne(requestParameters: Cardio3DControllerUpdateOneRequestParams, extraHttpRequestParams?: any): Promise<Observable<Cardio3D>>;

}
