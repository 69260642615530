/**
 * VRCardio Explore API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.42 (staging)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InteractiveImages } from './interactiveImages';


export interface ManyInteractiveImages { 
    data: Array<InteractiveImages>;
    count: number;
    total: number;
    page: number;
    pageCount: number;
}

